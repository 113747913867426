import { Alert, ThemeProvider, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import NavBar from 'components/Navigation/NavBar/NavBar'
import { Outlet } from 'react-router-dom'
import { finwsTheme } from 'utils/themeUtils'

export default function Parent() {
  return (
    <ThemeProvider theme={finwsTheme}>
      <CssBaseline>
        <NavBar />
        <Alert severity="error" variant='outlined' sx={{ m: 3, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body1">
            This application will be soon moved to a new location, kindly update your bookmark and use the new
            application found here:{' '}
            <a href="https://xrp-utils.cern.ch/contributions/tpa" target="_blank">
              https://xrp-utils.cern.ch/contributions/tpa
            </a> .
          </Typography>
        </Alert>
        <Outlet />
      </CssBaseline>
    </ThemeProvider>
  )
}
